










import { Component, Prop, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import { mdiFolderZipOutline } from "@mdi/js";
@Component({
  components: {
    DialogDownload: () => import("@/components/Menu/DialogDownload.vue")
  }
})
export default class LabelZip extends Vue {
  @Prop() readonly id!: string;
  @Prop() readonly number!: string;
  private icons: IconsType = {
    zip: mdiFolderZipOutline
  };
  get name() {
    return `${this.number}.zip`;
  }
}
